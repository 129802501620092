


























































































































import Vue from 'vue'
import axios, { AxiosResponse } from 'axios'

import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import FileDragDrop from '@/components/common/FileDragDrop.vue'
import { FileDetail, FileUploadError, UploadResult } from '@/assets/interfaces/common'

interface Data {
  selectedFiles: Array<File>
  selectedError: {
    isError: boolean
    text: string
  }
  uploadedErrors: Array<FileUploadError>
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
      itemDataNotFound: string
      item3DUpload: string
    }
    success: {
      item3DUpload: string
    },
    warning: {
      item3DUpload: string
    }
  }
}

export default Vue.extend({
  name: 'ItemDataUpload',
  components: {
    FileDragDrop
  },
  data() : Data {
    return {
      selectedFiles: [],
      selectedError: {
        isError: false,
        text: ''
      },
      uploadedErrors: [],
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。',
          item3DUpload: '全てのファイルアップロードが失敗されました。',
          itemDataNotFound: 'この3D画像の品目データは登録されていません。'
        },
        success: {
          item3DUpload: '3D画像ファイルはアップロードされました。'
        },
        warning: {
          item3DUpload: '3D画像ファイルはアップロードされましたがあるファイルが失敗されました。'
        }
      }
    }
  },
  computed: {
    fileDetails(): Array<FileDetail> {
      const formatedFileDetails: Array<FileDetail> = this.selectedFiles.map((file: File, index: number) => {
        let error = ''
        if (!file.name.includes('.u3ma') && !file.name.includes('.zfab')) {
          error = `${file.name}のファイル形式が不正です。`
        } else if (!/^[0-9A-Za-z-_]{11}_[0-9A-Za-z]{3}.(u3ma|zfab)$/.test(file.name)) {
          error = `${file.name}のファイル名が不正です。（形：{item_code}_{color}.{u3ma|zfab}）`
        }
        return {
          index: index,
          fileObject: file,
          error: {
            isError: !!error,
            message: error
          }
        }
      })
      return formatedFileDetails
    }
  },
  watch: {
    selectedFiles(files: Array<File>) {
      let count = 0
      files.forEach((file: File) => {
        if (!/^[0-9A-Za-z-_]{11}_[0-9A-Za-z]{3}.(u3ma|zfab)$/.test(file.name)) {
          count += 1
        }
      })
      if (count > 0) {
        this.selectedError.isError = true
        this.selectedError.text = `不正なファイル形式（${count}ファイル）があります。再ドラッグ＆ドロップまたは各ファイルを確認してください。`
      } else {
        this.selectedError.isError = false
        this.selectedError.text = ''
      }
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: '',
        show: true,
        color: 'danger'
      }
      if (err.message) {
        snackbarProps.display = err.message
        snackbarProps.color = err.color ? err.color : 'danger'
      } else {
        snackbarProps.display = this.getErrorMessage(err)
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    getErrorMessage(err: any): string {
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          return this.messages.errors.forbidden
        }
        if (err.request.status === 401) {
          return this.messages.errors.token
        }
        if (err.request.status === 404) {
          return this.messages.errors.itemDataNotFound
        }
      }
      return this.messages.errors.unknown
    },
    async uploadFiles() {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const errors: Array<FileUploadError> = []
        const promises = this.selectedFiles.map((file: File) => {
          return this.uploadFile(file)
        })
        const results: Array<UploadResult> = await Promise.all(promises)
        results.forEach((result: UploadResult, index: number) => {
          if (result.isError) {
            errors.push(
              {
                fileName: this.selectedFiles[index].name,
                error: {
                  message: result.message
                }
              }
            )
          }
        })
        if (errors.length > 0) {
          this.uploadedErrors = [
            ...errors
          ]
          this.$bvModal.show('item3DUploadErrorResultModal')
          /* eslint-disable no-throw-literal */
          throw {
            color: errors.length === this.selectedFiles.length ? 'danger' : 'warning',
            message: errors.length === this.selectedFiles.length ? this.messages.errors.item3DUpload : this.messages.warning.item3DUpload
          }
          /* eslint-disable no-throw-literal */
        }
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.item3DUpload,
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.resetFile()
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async uploadFile(file: File): Promise<UploadResult> {
      try {
        const matchedFileName = file.name.match(/^([0-9A-Za-z-_]{11})_([0-9A-Za-z]{3}).(u3ma|zfab)$/)!!
        const contentType = matchedFileName[3] === 'u3ma' ? 'application/zip' : 'application/octet-stream'
        const params = {
          color: matchedFileName[2]
        }
        const { data: { presigned_url: presignedUrl } }: AxiosResponse = await this.$axios.post(
          `fabric/${matchedFileName[1]}/${matchedFileName[3]}`,
          {},
          {
            params: params
          }
        )
        await axios.put(
          presignedUrl,
          file,
          {
            headers: {
              'Content-Type': contentType,
            }
          }
        )
        return {
          isError: false,
          message: ''
        }
      } catch (err: any) {
        return {
          isError: true,
          message: this.getErrorMessage(err)
        }
      }
    },
    resetFile() {
      this.selectedFiles = []
      this.selectedError = {
        isError: false,
        text: ''
      }
    },
    deleteFile(index: number) {
      this.$delete(this.selectedFiles, index)
    }
  },
  metaInfo: {
    title: '3D画像データアップロード'
  }
})
