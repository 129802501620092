import { LabelValuePair } from './common'

export interface IItem {
  itemCode: string
  name: string
  description: string
  defaultColor: boolean
  color: string
  section: string
  size: string
  composition: string
  weight: string
  fabricType: string
  pattern: {
    type: string
    patterns?: Array<string>
  }
  feeling: Array<string>
  appearance: Array<string>
  item: Array<string>
  function: Array<string>
  sustainable: Array<string>
  deliveryStatus: 'PREPARATION' | 'AVAILABLE'
  textile3DFile: {
    u3ma?: string
    zfab?: string
  }
}

export interface IItemTable {
  itemCode: string
  color: string
  defaultColor: boolean,
  section: string
  name: string
  thumbnail: string
  deliveryStatus: 'PREPARATION' | 'AVAILABLE'
  textile3DFile: {
    u3ma?: string
    zfab?: string
  }
}

export const deliveryStatusDropdownList: LabelValuePair[] = [
  {
    label: '-- 状態選択 --',
    value: ''
  },
  {
    label: '○',
    value: 'AVAILABLE'
  },
  {
    label: '×',
    value: 'PREPARATION'
  }
]

export const fileStatusDropdownList: LabelValuePair[] = [
  {
    label: '-- 状態選択 --',
    value: ''
  },
  {
    label: '○',
    value: 'AVAILABLE'
  },
  {
    label: '×',
    value: 'IMPOSSIBLE'
  }
]

export const defaultDropdownList: LabelValuePair[] = [
  {
    label: '○',
    value: true
  },
  {
    label: '×',
    value: false
  }
]
