











































































































































import Vue from 'vue'
import axios, { AxiosResponse } from 'axios'
import * as xlsx from 'xlsx'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import {
  ValidateErrorCode,
  ValidateWarningCode,
  IValidateDetail,
  ValidateResult
} from '@/assets/interfaces/common'
import { getHeader, readFileAsBinaryString } from '@/assets/utils'

interface Data {
  selectedFile: File | null
  validatedResult: ValidateResult
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
    }
    success: {
      excelUpload: string
    }
  }
  loading: boolean
}

export default Vue.extend({
  name: 'ItemDataUpload',
  data() : Data {
    return {
      selectedFile: null,
      loading: false,
      validatedResult: {
        valid: false,
        filePreviews: {
          headers: [],
          items: []
        },
        errors: [],
        warnings: []
      },
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。'
        },
        success: {
          excelUpload: 'エクセルファイルがアップロードされました。'
        }
      }
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger'
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    async uploadFile() {
      if (!this.selectedFile) {
        return
      }
      try {
        this.$eventbus.$emit('pageLoading', true)
        const { data: { presigned_url: presignedUrl } }: AxiosResponse = await this.$axios.post(
          'fabrics'
        )
        await axios.put(
          presignedUrl,
          this.selectedFile,
          {
            headers: {
              'Content-Type': this.selectedFile.type,
            }
          }
        )
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.excelUpload,
          show: true,
          color: 'success'
        }
        this.resetFile()
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (err: any) {
        this.handleError(err)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    resetFile() {
      this.selectedFile = null
      this.clearvalidatedResult()
    },
    async selectedFileCallback(targetFile: File) {
      this.clearvalidatedResult()
      if (targetFile && targetFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.validatedResult = {
          valid: false,
          filePreviews: {
            headers: [],
            items: []
          },
          errors: [
            {
              code: ValidateErrorCode.INVALID_FILE_TYPE,
              message: `${targetFile.name}のファイル形式が不正です。`
            }
          ],
          warnings: []
        }
      } else if (targetFile) {
        const result = await this.validateFile(targetFile)
        if (result) {
          this.validatedResult = result
        }
      }
    },
    clearvalidatedResult() {
      this.validatedResult = {
        valid: false,
        filePreviews: {
          headers: [],
          items: []
        },
        errors: [],
        warnings: []
      }
    },
    async validateFile(targetFile: File): Promise<ValidateResult | void> {
      const expectedHeader = {
        schema: [
          'item_code',
          'color',
          'section',
          'name',
          'fabric_type',
          'width',
          'height',
          'weight',
          'composition',
          'function1',
          'function2',
          'function3',
          'function4',
          'function5',
          'sustainable1',
          'sustainable2',
          'sustainable3',
          'sustainable4',
          'sustainable5',
          'description',
          'pattern_type',
          'pattern1',
          'pattern2',
          'pattern3',
          'pattern4',
          'pattern5',
          'feeling',
          'appearance',
          'item'
        ],
        value: ['SAP品番（スタイルコード）', '色番', '課', 'スタイル名', '生地分類', '反幅（有効幅）', '定メータ', 'SQM重量', '組成', '機能（暖かさ）', '機能（爽やかさ）', '機能（清潔さ）', '機能（安心・安全）', '機能（イージーケア）', 'サステナビリティ（環境配慮）', 'サステナビリティ（リサイクル）', 'サステナビリティ（動物愛護）', 'サステナビリティ（オーガニック）', 'サステナビリティ（森林保護）', '商品説明', '柄／無地', '柄1', '柄2', '柄3', '柄4', '柄5', '風合い', '生地の表情', 'アイテム']
      }
      let valid = true
      let headers: string[] = []
      const items: any[] = []
      let errors: IValidateDetail[] = []
      const warnings: IValidateDetail[] = []
      const validateHeader = (header: string[]) => {
        let valid = true
        const errors: IValidateDetail[] = []
        if (header.length < expectedHeader.value.length) {
          valid = false
          errors.push(
            {
              code: ValidateErrorCode.INVALID_HEADER,
              message: 'カラム数が不足しています。'
            }
          )
        } else {
          for (let index = 0; index < expectedHeader.value.length; index += 1) {
            if (header[index] !== expectedHeader.value[index]) {
              valid = false
              errors.push(
                {
                  code: ValidateErrorCode.INVALID_HEADER,
                  message: `${index + 1}列目の名前は正しくありません。（正常値：${expectedHeader.value[index]}）`
                }
              )
            }
          }
        }
        return {
          valid,
          errors
        }
      }
      const processItem = (row: number, key: string, item: any) => {
        let error: IValidateDetail | undefined
        const column = expectedHeader.schema.indexOf(key)
        const requiredItems = [
          'item_code',
          'name',
          'description',
          'composition',
          'section',
          'width',
          'height',
          'color',
          'weight',
          'fabric_type'
        ]
        const regexItem: any = {
          item_code: /^[0-9A-Za-z-_]{11}$/,
          color: /^[0-9A-Za-z]{3}$/,
          width: /^(0|[1-9][0-9]{0,3})$/,
          height: /^(0|[1-9][0-9]{0,3})$/,
          weight: /^[1-9][0-9]*(\.[0-9]{1,3})?$/,
          section: /^\d*$/,
          pattern_type: /^(柄|無地|柄,無地|^)$/
        }
        if (requiredItems.includes(key) && !item) {
          error = {
            code: ValidateErrorCode.INVALID_ITEM,
            message: `[${row}行目/${column + 1}列目/${expectedHeader.value[column]}]：必須`
          }
        } else if (regexItem[key] && !regexItem[key].test(`${item !== undefined ? item : ''}`)) {
          error = {
            code: ValidateErrorCode.INVALID_ITEM,
            message: `[${row}行目/${column + 1}列目/${expectedHeader.value[column]}]：形式が正しくありません。`
          }
        }
        const rowItem = {
          key: key,
          error: error,
          text: (item !== undefined) ? `${item}` : ''
        }
        return {
          error,
          rowItem
        }
      }
      try {
        this.loading = true
        this.$eventbus.$emit('pageLoading', true)
        const data = await readFileAsBinaryString(targetFile)
        const wb = xlsx.read(data, {
          type: 'binary'
        })
        if (wb.SheetNames.length > 1) {
          warnings.push(
            {
              code: ValidateWarningCode.SHEET_NUMBER,
              message: 'シート数が2シート以上あるため、最初のシート以外無視されます。'
            }
          )
        }
        const ws = wb.Sheets[wb.SheetNames[0]]
        const wsObject = xlsx.utils.sheet_to_json(ws, {
          header: expectedHeader.schema
        })
        headers = getHeader(ws)
        const validatedHeader = validateHeader(headers)
        if (!validatedHeader.valid) {
          errors = [
            ...errors,
            ...validatedHeader.errors
          ]
          valid = false
          return
        }
        headers = [
          ...expectedHeader.value
        ]
        const uniqueValues = new Set();
        wsObject.forEach((value: any, row: number) => {
          if (row === 0) {
            return
          }
          // Duplicate check
          const itemCode = value.item_code
          const colorCode = value.color
          const checkKey = `${itemCode}-${colorCode}`
          if (uniqueValues.has(checkKey)) {
            // SAP品番-色版 重複
            const duplicateError = {
              code: ValidateErrorCode.INVALID_ITEM,
              message: `${row}行目のSAP品番+色番シート内で重複してます。`
            }
            errors.push(duplicateError)
          } else {
            uniqueValues.add(checkKey);
          }

          const rowItems: any[] = []
          for (let index = 0; index < expectedHeader.schema.length; index += 1) {
            const key = expectedHeader.schema[index]
            const { error, rowItem } = processItem(row, key, value[key])
            rowItems.push(
              rowItem
            )
            if (error) {
              errors.push(
                error
              )
            }
          }
          items.push(rowItems)
        })
        valid = errors.length === 0
      } catch (err: any) {
        this.handleError(err)
        valid = false
      } finally {
        this.$eventbus.$emit('pageLoading', false)
        this.loading = false
        return {
          valid,
          filePreviews: {
            headers: headers,
            items: items
          },
          errors,
          warnings
        }
      }
    }
  },
  metaInfo: {
    title: '品目データアップロード'
  }
})
