

























































































































































































































































import Vue from 'vue'
import {
  ICustomerEntry,
  skuRelationRadioButtonList,
  billingPlanDropdownList,
  BillingPlan
} from '@/assets/interfaces/Customer'
import { LabelValuePair } from '@/assets/interfaces/common'

interface Data {
  skuRelationRadioButtonList: LabelValuePair[]
  billingPlanDropdownList: LabelValuePair[]
}

export default Vue.extend({
  name: 'CustomerEntryModal',
  props: {
    entryCustomerInfo: {
      type: Object as () => ICustomerEntry,
      required: true
    }
  },
  data(): Data {
    return {
      skuRelationRadioButtonList: skuRelationRadioButtonList,
      billingPlanDropdownList: billingPlanDropdownList
    }
  },
  computed: {
    billingPlanText(): string {
      return this.entryCustomerInfo.billingPlan ? (BillingPlan as any)[this.entryCustomerInfo.billingPlan] : '課金プランを選択'
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: any) {
      return dirty || validated ? valid : null
    }
  }
})
