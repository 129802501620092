










import Vue from 'vue'
import Navbar from '@/components/common/Navbar.vue'
import LoadingWrap from '@/components/common/LoadingWrap.vue'
import Snackbar from '@/components/common/Snackbar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
    LoadingWrap,
    Snackbar
  },
  metaInfo: {
    title: 'Stylem 3D Img DL',
    titleTemplate: '%s | Stylem 3D Img DL'
  }
})
