

































































































































































































































































































































import Vue from 'vue'
import { IItem, defaultDropdownList } from '@/assets/interfaces/Item'
import { LabelValuePair } from '@/assets/interfaces/common'

interface Data {
  defaultDropdownList: LabelValuePair[]
}

export default Vue.extend({
  name: 'ItemDetailModal',
  props: {
    itemInfo: {
      type: Object as () => IItem,
      required: true
    }
  },
  data(): Data {
    return {
      defaultDropdownList: defaultDropdownList
    }
  },
  computed: {
    formatedFunction(): string {
      return (this.itemInfo as IItem).function.join('・')
    },
    formatedSustainable() {
      return (this.itemInfo as IItem).sustainable.join('・')
    },
    formatedPattern() {
      return (this.itemInfo as IItem).pattern.patterns ? (this.itemInfo as IItem).pattern.patterns!!.join('・') : ''
    },
    formatedFeeling() {
      return (this.itemInfo as IItem).feeling.join('・')
    },
    formatedAppearance() {
      return (this.itemInfo as IItem).appearance.join('・')
    },
    formatedItem() {
      return (this.itemInfo as IItem).item.join('・')
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: any) {
      return dirty || validated ? valid : null
    }
  }
})
