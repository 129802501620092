














































import Vue from 'vue'
import NavSidebarIconItem from './NavSidebarIconItem.vue'
import NavSidebarItem from './NavSidebarItem.vue'

interface Data {
  collapsed: boolean
}

export default Vue.extend({
  components: { NavSidebarItem, NavSidebarIconItem },
  name: 'NavSidebar',
  props: {
    item: {
      type: Array,
      default: () => []
    }
  },
  data() : Data {
    return {
      collapsed: false
    }
  },
  computed: {
    hasItemSlot() : boolean {
      return !!this.$slots.item
    },
    currentParentRouteName() : string {
      if (!this.$route.name) {
        return ''
      }
      const prefixPage = this.$route.name.split('-')[0]
      switch (prefixPage) {
        case 'Home':
          return 'index'
        case 'Item':
          return 'item'
        case 'Customer':
          return 'customer'
        default:
          return ''
      }
    }
  }
})
