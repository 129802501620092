





















































































































import Vue from 'vue'
import { IUser } from '@/assets/interfaces/User'

export default Vue.extend({
  name: 'UserDetailModal',
  props: {
    userInfo: {
      type: Object as () => IUser,
      required: true
    }
  },
  computed: {
    customerText(): string {
      return this.userInfo.customerId ? `${this.userInfo.customerName ? this.userInfo.customerName : '不明'} <${this.userInfo.customerId}>` : 'なし'
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: any) {
      return dirty || validated ? valid : null
    }
  }
})
