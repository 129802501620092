export interface LabelValuePair {
  label?: string
  text?: string
  value: string | number | boolean
}

export interface TableField {
  key: string
  label: string
  tdClass?: string
  thStyle?: string
  thClass?: string
  class?: string
  sortable?: boolean
}

export interface TableProps {
  fields: TableField[]
  filterOn: string[]
  filter: any
  totalRows: number
  perPage: number
  currentPage: number
  busy: boolean
}

export enum ValidateErrorCode {
  UNKNOWN = 'unknown',
  INVALID_HEADER = 'invalid_header',
  INVALID_ITEM = 'invalid_item',
  INVALID_FILE_TYPE = 'invalid_file_type'
}

export enum ValidateWarningCode {
  SHEET_NUMBER = 'sheet_number'
}

export interface IValidateDetail {
  code: ValidateErrorCode | ValidateWarningCode
  message: string
}

interface ErrorDetail {
  isError: boolean,
  message: string
}

export interface FileDetail {
  index: number,
  fileObject: File,
  error: ErrorDetail
}

export interface FileUploadError {
  fileName: string
  error: {
    message: string
  }
}

export interface ValidateResult {
  valid: boolean
  filePreviews: {
    headers: string[]
    items: any[]
  }
  errors: IValidateDetail[]
  warnings: IValidateDetail[]
}

export type UploadResult = ErrorDetail
