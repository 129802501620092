























































































import Vue from 'vue'
import { IUserEntry } from '@/assets/interfaces/User'

export default Vue.extend({
  name: 'UserEntryModal',
  props: {
    entryUserInfo: {
      type: Object as () => IUserEntry,
      required: true
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: any) {
      return dirty || validated ? valid : null
    }
  }
})
