import Encoding from 'encoding-japanese'
import * as xlsx from 'xlsx'

export const clearEmpties = (object: any, includeZero = false) => {
  if (!object) {
    return
  }
  Object.keys(object).forEach((key) => {
    if (object[key] !== null) {
      if (typeof object[key] === 'object') {
        clearEmpties(object[key])
        if (Object.keys(object[key]).length === 0) {
          delete object[key]
        }
      } else if (typeof object[key] === 'string') {
        if (!object[key]) {
          delete object[key]
        }
      } else if (typeof object[key] === 'number') {
        if (!includeZero && !object[key]) {
          delete object[key]
        }
      }
    } else {
      delete object[key]
    }
  })
}

export const appendLeadingZeroes = (n: number) => {
  if (n <= 9) {
    return `0${n}`
  }
  return n
}

const processRow = (row: Array<any>) => {
  let finalVal = ''
  for (let j = 0; j < row.length; j += 1) {
    let innerValue = row[j] === null ? '' : row[j].toString()
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }
    let result = innerValue.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) {
      result = `"${result}"`
    }
    if (j > 0) {
      finalVal += ','
    }
    finalVal += result
  }
  return `${finalVal}\n`
}

export const exportToCsv = (fileName: string, header: Array<string>, rows: Array<any[]>) => {
  let csvFile = ''
  csvFile += processRow(header)
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  const codes = Encoding.stringToCode(csvFile)
  const shiftJisCodeList = Encoding.convert(codes, 'SJIS', 'UNICODE')
  const uInt8List = new Uint8Array(shiftJisCodeList)

  const blob = new Blob([uInt8List], { type: 'text/csv;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click();
    document.body.removeChild(link)
  }
}

export default {
  clearEmpties,
  appendLeadingZeroes,
  exportToCsv
}

export const readFileAsBinaryString = (targetFile: File): Promise<any> => {
  const fileReader = new FileReader()
  return new Promise((resolve: any, reject: any) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      resolve(ev.target?.result)
    }
    fileReader.readAsBinaryString(targetFile)
  })
}

export const getHeader = (ws: xlsx.WorkSheet): string[] => {
  const headers: string[] = []
  const range = xlsx.utils.decode_range(ws['!ref']!!)
  const R = range.s.r
  for (let C = range.s.c; C <= range.e.c; C += 1) {
    const cell = ws[
      xlsx.utils.encode_cell({ c: C, r: R })
    ]
    let hdr = `UNKNOWN${C}`
    if (cell && cell.t) {
      hdr = xlsx.utils.format_cell(cell)
    }
    headers.push(hdr)
  }
  return headers
}
