import { required as veeRequired, integer as veeInteger } from 'vee-validate/dist/rules'

export const required = {
  ...veeRequired,
  message: '{_field_}は必須です。'
}

export const size = {
  validate: (v: any) => (/^(0|[1-9][0-9]{0,3})X(0|[1-9][0-9]{0,3})$/.test(v)),
  message: '{_field_}は形式が正しくありません。(例：100X100)'
}

export const integer = {
  ...veeInteger,
  message: '{_field_}は整数です。'
}

export const positiveInteger = {
  validate: (v: any) => (/^\d*$/.test(v)),
  message: '{_field_}は正整数です。'
}

export const weight = {
  validate: (v: any) => (/^[1-9][0-9]*(\.[0-9]{1,3})?$/.test(v)),
  message: '{_field_}の形式が不正です。'
}

export const downloadQuota = {
  validate: (v: any) => (/^(-1|\d*)$/.test(v)),
  message: '{_field_}は形式が正しくありません。(例：10、0、-1)'
}

export const totalDownloadQuota = {
  params: ['fabric', 'texture'],
  validate: (v: any, args: any) => {
    if (!v || !args.fabric || !args.texture) {
      return true
    }
    if (Number(v) !== -1) {
      if ((Number(args.fabric) === -1 || Number(args.texture) === -1)) {
        return '{_field_}は正しくありません。（正常値：-1）'
      }
      if (Number(v) < Number(args.fabric) || Number(v) < Number(args.texture)) {
        return `{_field_}は正しくありません。（正常値：${Number(args.fabric) > Number(args.texture) ? Number(args.fabric) : Number(args.texture)}以上）`
      }
    }
    return true
  }
}

const email = {
  validate: (v: any) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v),
  message: '{_field_}の形式が不正です。'
}

export default {
  required,
  size,
  integer,
  positiveInteger,
  email,
  downloadQuota,
  totalDownloadQuota,
  weight
}
