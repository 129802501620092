




















import Vue from 'vue'

interface Data {
  isDragEnter: boolean,
  loading: boolean
}

export default Vue.extend({
  name: 'FileDragDrop',
  props: {
    value: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'ドラッグ＆ドロップでコンテンツ登録'
    }
  },
  data(): Data {
    return {
      isDragEnter: false,
      loading: false
    }
  },
  methods: {
    dragEnter() {
      this.isDragEnter = true
    },
    dragLeave() {
      this.isDragEnter = false
    },
    dropFile(e: any) {
      this.loading = true
      this.$emit('input', Array.from(e.dataTransfer.files))
      this.loading = false
      this.isDragEnter = false
    }
  }
})
