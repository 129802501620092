
















import Vue from 'vue'
import Navbar from '@/components/common/Navbar.vue'
import NavSidebar from '@/components/common/NavSidebar.vue'

import { INavSidebarItem } from '@/assets/interfaces/component/NavSidebar'

interface Data {
  headerItem: Array<INavSidebarItem>
}

export default Vue.extend({
  name: 'Default',
  components: {
    Navbar,
    NavSidebar
  },
  data() : Data {
    return {
      headerItem: [
        {
          name: 'index',
          icon: 'house-door-fill',
          label: 'Home',
          href: '/',
          items: []
        },
        {
          name: 'item',
          icon: 'person-square',
          label: '品目データ管理',
          href: '/Item/List',
          items: [
            {
              name: 'item-data-upload',
              icon: '',
              label: '品目データアップロード',
              href: '/Item/ItemDataUpload',
            },
            {
              name: 'item-data-3d-upload',
              icon: '',
              label: '3D画像データアップロード',
              href: '/Item/Item3DUpload',
            },
            {
              name: 'item-list',
              icon: '',
              label: '品目一覧',
              href: '/Item/List',
            },
            {
              name: 'item-download-history-list',
              icon: '',
              label: '3D画像ダウンロード履歴一覧',
              href: '/DownloadHistory/List',
            }
          ]
        },
        {
          name: 'customer',
          icon: 'briefcase',
          label: '顧客データ管理',
          href: '/Customer/List',
          items: [
            {
              name: 'customer-list',
              icon: '',
              label: '顧客一覧',
              href: '/Customer/List',
            }
          ]
        }
      ]
    }
  }
})
