import { LabelValuePair } from './common'

export enum BillingPlan {
  PAY_AS_YOU_GO = '都度課金',
  TX = 'TXプラン',
  PREMIUM = 'プレミアムプラン',
  M2M = 'M2M'
}

export interface ICustomer {
  customerId: string
  customerName: string
  sapCustomerCode?: string
  skuRelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE'
  fabricDownloadQuota: string
  textureDownloadQuota: string
  totalDownloadQuota: string
  billingPlan: string
  assetAmount: string
  status: string
  createdAt: string
}

export interface ICustomerTable {
  customerId: string
  customerName: string
  skuRelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE'
  billingPlan: string
  assetAmount: number
  totalDownloadQuota: number
  status: string
}

export interface ICustomerEntry {
  customerName: string
  sapCustomerCode: string
  skuRelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE'
  fabricDownloadQuota: string
  textureDownloadQuota: string
  totalDownloadQuota: string
  billingPlan: string
  assetAmount: string
}

export const customerStatusRadioButtonList: LabelValuePair[] = [
  {
    text: 'Active',
    value: 'Active'
  },
  {
    text: 'Inactive',
    value: 'Inactive'
  }
]

export const skuRelationRadioButtonList: LabelValuePair[] = [
  {
    text: 'あり（デフォルト）',
    value: 'ITEM_CODE_AND_COLOR'
  },
  {
    text: 'なし',
    value: 'ITEM_CODE'
  }
]

export const billingPlanDropdownList: LabelValuePair[] = [
  {
    text: '都度課金',
    value: 'PAY_AS_YOU_GO'
  },
  {
    text: 'TXプラン',
    value: 'TX'
  },
  {
    text: 'プレミアムプラン',
    value: 'PREMIUM'
  },
  {
    text: 'M2M',
    value: 'M2M'
  }
]
