import Vue from 'vue'
import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js'

export interface Auth0Data {
  loading: boolean
  isAuthenticated: boolean
  user: User | undefined
  auth0Client: Auth0Client | null
  popupOpen: boolean
  error: any
  jwtToken: string | null
  logoutUri?: string
}

interface Options {
  onRedirectCallback: any
  domain: string
  clientId: string
  audience: string
  scope?: string
  relayState?: string
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (o: any) => window.history.replaceState({}, document.title, window.location.pathname)

let instance: Vue

export const getInstance = () : Vue => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  logoutUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() : Auth0Data {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        jwtToken: null,
        logoutUri: undefined
      }
    },
    methods: {
      async loginWithPopup(options: any, config: any) {
        this.popupOpen = false
        try {
          await this.auth0Client!!.loginWithPopup(options, config)
          this.user = await this.auth0Client!!.getUser()
          this.isAuthenticated = await this.auth0Client!!.isAuthenticated()
          this.error = null
        } catch (e) {
          console.error(e)
        } finally {
          this.popupOpen = false
        }
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client!!.handleRedirectCallback()
          this.user = await this.auth0Client!!.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: any = undefined) {
        return this.auth0Client!!.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: any = undefined) {
        return this.auth0Client!!.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o: any = undefined) {
        return this.auth0Client!!.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o: any = undefined) {
        return this.auth0Client!!.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o: any = undefined) {
        return this.auth0Client!!.logout({
          returnTo: this.logoutUri,
          ...o
        })
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      this.auth0Client = await createAuth0Client({
        ...(options as Options),
        client_id: options.clientId,
        redirect_uri: redirectUri,
        cacheLocation: (window as any).Cypress ? 'localstorage' : 'memory'
      })
      this.logoutUri = logoutUri
      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=')
          && window.location.search.includes('state=')
        ) {
          const urlParams = new URLSearchParams(window.location.search)
          if (urlParams.get('state') === options.relayState) {
            this.loginWithRedirect()
          }
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
        if (
          window.location.search.includes('error=')
          && window.location.search.includes('error_description=')
          && window.location.search.includes('state=')
        ) {
          const urlParams = new URLSearchParams(window.location.search)
          window.sessionStorage.setItem('error', urlParams.get('error')!!)
          window.sessionStorage.setItem('error_description', urlParams.get('error_description')!!)
          this.logout()
        }
      } catch (e) {
        console.error(e)
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.loading = false
      }
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(vue: any, options: Options) {
    vue.prototype.$auth = useAuth0(options)
  }
}
