



















































































































import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import { AxiosResponse } from 'axios'
import UserEntryModal from '@/components/User/UserEntryModel.vue'
import UserDetailModal from '@/components/User/UserDetailModel.vue'
import { TableField } from '@/assets/interfaces/common'
import { IUser, IUserEntry, IUserTable } from '@/assets/interfaces/User'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import { clearEmpties } from '@/assets/utils'

interface Data {
  newEntryUser: IUserEntry
  selectedUser: IUser
  modalLoading: boolean
  tableProps: {
    headers: Array<TableField>
    items: Array<IUserTable>
    perPage: number
    noDataText: string
    pageStack: Array<string | null>
    nextPage: string | null
    tableLoading: boolean
    currentPage: string | null
  }
  messages: {
    errors: {
      userExist: string
      unknown: string
      forbidden: string
      token: string
    }
    success: {
      userCreate: string
      userEdit: string
      userDelete: string
    }
  }
}

export default Vue.extend({
  name: 'UserList',
  components: {
    UserEntryModal,
    UserDetailModal
  },
  data(): Data {
    return {
      newEntryUser: {
        name: '',
        email: '',
        divisionName: '',
        customerId: ''
      },
      selectedUser: {
        userId: '',
        name: '',
        email: '',
        divisionName: '',
        customerId: '',
        customerName: '',
        createdAt: '',
        billingPlan: ''
      },
      modalLoading: false,
      tableProps: {
        headers: [
          {
            key: 'userId',
            label: 'ユーザーID',
            sortable: false,
          },
          {
            key: 'name',
            label: 'ユーザー名',
            sortable: false
          },
          {
            key: 'divisionName',
            label: '部門',
            sortable: false
          },
          {
            key: 'billingPlan',
            label: '課金プラン',
            sortable: false
          },
          {
            key: 'actions',
            label: 'アクション',
            class: 'text-center',
            sortable: false
          }
        ],
        items: [],
        perPage: 10,
        noDataText: 'データが見つかりません',
        tableLoading: false,
        pageStack: [],
        nextPage: null,
        currentPage: null
      },
      messages: {
        errors: {
          userExist: '入力したメールアドレスが存在しています。',
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。'
        },
        success: {
          userCreate: 'ユーザー情報は作成されました。',
          userEdit: 'ユーザー情報は作成されました。',
          userDelete: 'ユーザー情報(#USER_ID)は削除されました。'
        }
      }
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger'
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        } else if (err.request.status === 400 && err.response.data.Code === 'UserExistError') {
          snackbarProps.display = this.messages.errors.userExist
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    async showDetailModal(userId: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const splitUserId = userId.split('|').pop()
        const { data }: AxiosResponse = await this.$axios.get(
          `user/${splitUserId}`
        )
        this.selectedUser = {
          userId: data.user_id,
          name: data.name,
          email: data.email,
          divisionName: data.division_name,
          customerId: data.customer_id,
          customerName: data.customer_name,
          createdAt: data.created_at,
          billingPlan: data.billingPlan
        }
        this.$bvModal.show('userDetailModal')
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async createUser() {
      try {
        const userEntryForm: InstanceType<typeof ValidationObserver> = (this.$refs.userEntry as any).$refs.entryForm
        if (!userEntryForm) {
          return
        }
        const result = await userEntryForm.validate()
        if (!result) {
          return
        }
        this.modalLoading = true
        const payload = {
          name: this.newEntryUser.name,
          email: this.newEntryUser.email,
          division_name: this.newEntryUser.divisionName,
          customer_id: this.newEntryUser.customerId
        }
        clearEmpties(payload)
        await this.$axios.post(
          'user',
          payload
        )
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.userCreate,
          show: true,
          color: 'success'
        }
        this.$bvModal.hide('userEntryModal')
        this.$eventbus.$emit('showSnackbar', snackbarProps)
        await this.fetchList()
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
      }
    },
    async updateUser() {
      try {
        const userEditForm: InstanceType<typeof ValidationObserver> = (this.$refs.userDetail as any).$refs.editForm
        if (!userEditForm) {
          return
        }
        const result = await userEditForm.validate()
        if (!result) {
          return
        }
        this.modalLoading = true
        const payload = {
          name: this.selectedUser.name,
          email: this.selectedUser.email,
          division_name: ''
        }
        clearEmpties(payload)
        payload.division_name = this.selectedUser.divisionName
        const splitUserId = this.selectedUser.userId.split('|').pop()
        const { data }: AxiosResponse = await this.$axios.post(
          `user/${splitUserId}`,
          payload
        )
        const targetIndex = this.tableProps.items.findIndex((value: IUserTable) => value.userId === this.selectedUser.userId)
        if (targetIndex !== -1) {
          this.$set(this.tableProps.items, targetIndex, {
            userId: data.user_id,
            name: data.name,
            divisionName: data.division_name
          })
        }
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.userEdit,
          show: true,
          color: 'success'
        }
        this.$bvModal.hide('userDetailModal')
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
      }
    },
    async deleteUser(userId: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const splitUserId = userId.split('|').pop()
        await this.$axios.delete(
          `user/${splitUserId}`
        )
        this.clearPagination()
        this.fetchList()
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.userDelete.replace('#USER_ID', userId),
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    showUserDeleteConfirm(userId: string) {
      this.$bvModal.msgBoxConfirm(`ユーザー情報（${userId}）を削除しますか？`, {
        title: 'ユーザー削除',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '削除',
        cancelTitle: 'キャンせル',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value: boolean) => {
        if (value) {
          this.deleteUser(userId)
        }
      })
    },
    async fetchList() {
      try {
        this.tableProps.tableLoading = true
        const params = {
          limit: this.tableProps.perPage,
          page: this.tableProps.nextPage,
          customer_id: this.$route.params.id
        }
        clearEmpties(params)
        const { data }: AxiosResponse = await this.$axios.get(
          'users',
          {
            params: params
          }
        )
        this.tableProps.items = data.users.map((item: any): IUserTable => {
          return {
            userId: item.user_id,
            name: item.name,
            divisionName: item.division_name,
            billingPlan: item.billing_plan
          }
        })
        this.tableProps.currentPage = this.tableProps.nextPage
        this.tableProps.nextPage = data.next_page
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.tableProps.tableLoading = false
      }
    },
    async previousPage() {
      if (this.tableProps.pageStack.length > 0) {
        this.tableProps.nextPage = this.tableProps.pageStack.pop()!!
        await this.fetchList()
      }
    },
    async nextPage() {
      this.tableProps.pageStack.push(this.tableProps.currentPage)
      await this.fetchList()
    },
    showEntryModal() {
      this.newEntryUser = {
        name: '',
        email: '',
        divisionName: '',
        customerId: this.$route.params.id
      }
      this.$bvModal.show('userEntryModal')
    },
    clearPagination() {
      this.tableProps.pageStack = []
      this.tableProps.nextPage = null
      this.tableProps.currentPage = null
    }
  },
  async mounted() {
    await this.fetchList()
    this.$bvModal.show('UserEntryModal')
  },
  metaInfo: {
    title: 'ユーザー一覧'
  }
})
