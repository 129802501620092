
























import Vue from 'vue'

export default Vue.extend({
  name: 'ItemBatchDeleteModal',
  props: {
    skus: {
      type: Array,
      required: true
    },
    msg: {
      type: String,
      required: true
    }
  }
})
