
















































































































































































































































































































































































import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { ValidationObserver } from 'vee-validate'

import apiConfig from '@/assets/consistants/apiConfig'
import { clearEmpties } from '@/assets/utils'
import { TableField, LabelValuePair } from '@/assets/interfaces/common'
import {
  IItemTable,
  IItem,
  deliveryStatusDropdownList,
  fileStatusDropdownList
} from '@/assets/interfaces/Item'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import ItemDetailModal from '@/components/Item/ItemDetailModal.vue'
import ItemSkuDetailModal from '@/components/Item/ItemSkuDetailModal.vue'

interface Data {
  selectedSku: string[]
  checkedAll: boolean[]
  notFoundSku: string []
  selectedItem: IItem & {
    originalDefaultColor: boolean
  }
  tableProps: {
    headers: Array<TableField>
    items: Array<IItemTable>
    perPage: number
    noDataText: string
    tokenStack: Array<string | null>
    nextToken: string | null
    tableLoading: boolean
    currentToken: string | null
    filterInfo: {
      itemCode: string
      section: string
      deliveryStatus: string
      zfab: string
      u3ma: string
    }
  }
  deliveryStatusDropdownList: LabelValuePair[]
  zfabFileStatusDropdownList: LabelValuePair[]
  u3maFileStatusDropdownList: LabelValuePair[]
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
    }
    success: {
      itemEdit: string
      itemDelete: string
      batchDelete: string
    }
  }
  modalLoading: boolean
}

export default Vue.extend({
  name: 'ItemList',
  components: {
    ItemDetailModal,
    ItemSkuDetailModal
  },
  data(): Data {
    return {
      selectedSku: [],
      checkedAll: [],
      notFoundSku: [],
      selectedItem: {
        itemCode: '',
        name: '',
        description: '',
        defaultColor: false,
        originalDefaultColor: false,
        composition: '',
        color: '',
        section: '',
        size: '',
        weight: '',
        fabricType: '',
        pattern: {
          type: ''
        },
        feeling: [],
        appearance: [],
        item: [],
        function: [],
        sustainable: [],
        deliveryStatus: 'PREPARATION',
        textile3DFile: {}
      },
      tableProps: {
        headers: [
          {
            key: 'checkBox',
            label: '',
            class: 'text-center',
            sortable: false,
          },
          {
            key: 'thumbnail',
            label: 'サムネイル',
            class: 'text-center thumbnail-column',
            sortable: false,
          },
          {
            key: 'itemCode',
            label: '品番',
            sortable: false
          },
          {
            key: 'color',
            label: '色番',
            sortable: false
          },
          {
            key: 'defaultColor',
            label: 'デフォルト色',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'section',
            label: '課',
            sortable: false
          },
          {
            key: 'name',
            label: 'スタイル名',
            sortable: false
          },
          {
            key: 'deliveryStatus',
            label: '公開可能',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'textile3DFileu3ma',
            label: 'u3ma',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'textile3DFilezfab',
            label: 'zfab',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'actions',
            label: 'アクション',
            class: 'text-center',
            sortable: false
          }
        ],
        items: [],
        perPage: 10,
        noDataText: 'データが見つかりません',
        tableLoading: false,
        tokenStack: [],
        nextToken: null,
        currentToken: null,
        filterInfo: {
          itemCode: '',
          section: '',
          deliveryStatus: '',
          zfab: '',
          u3ma: ''
        }
      },
      deliveryStatusDropdownList: deliveryStatusDropdownList,
      zfabFileStatusDropdownList: fileStatusDropdownList,
      u3maFileStatusDropdownList: fileStatusDropdownList,
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。'
        },
        success: {
          itemEdit: '品目情報は更新されました。',
          itemDelete: '品目情報(#ITEM_CODE:#COLOR)は削除されました。',
          batchDelete: '品目情報は削除されました。'
        }
      },
      modalLoading: false
    }
  },
  computed: {
    downloadUrl() {
      return apiConfig.downloadUrl
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger'
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.response.config.method === 'delete' && err.request.status === 404 && err.response.data.sku) {
          this.notFoundSku = [
            ...err.response.data.sku
          ]
          this.$bvModal.show('itemBatchDeleteNotFoundModal')
        } else if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    async fetchList(refreshSamePage = false) {
      try {
        this.tableProps.tableLoading = true
        const params = {
          limit: this.tableProps.perPage,
          next_token: refreshSamePage ? this.tableProps.currentToken : this.tableProps.nextToken,
          delivery_status: this.tableProps.filterInfo.deliveryStatus,
          section: this.tableProps.filterInfo.section,
          item_code: this.tableProps.filterInfo.itemCode,
          u3ma: this.tableProps.filterInfo.u3ma,
          zfab: this.tableProps.filterInfo.zfab
        }
        clearEmpties(params)
        const { data }: AxiosResponse = await this.$axios.get(
          'fabrics',
          {
            params: params
          }
        )
        this.tableProps.items = data.fabrics.map((item: any): IItemTable => {
          return {
            itemCode: item.item_code,
            defaultColor: item.default_color,
            color: item.color,
            name: item.name,
            section: item.section,
            thumbnail: item.thumbnail,
            deliveryStatus: item.delivery_status,
            textile3DFile: {
              u3ma: item.fabric_files ? item.fabric_files.u3ma : undefined,
              zfab: item.fabric_files ? item.fabric_files.zfab : undefined
            }
          }
        })
        this.tableProps.currentToken = this.tableProps.nextToken
        this.tableProps.nextToken = data.next_token
        this.onCheckAllChange()
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.tableProps.tableLoading = false
      }
    },
    async showDetailModal(itemCode: string, color: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const params = {
          color: color
        }
        const { data }: AxiosResponse = await this.$axios.get(
          `fabric/${itemCode}`,
          {
            params: params
          }
        )
        this.selectedItem = {
          itemCode: data.item_code,
          name: data.name,
          composition: data.composition,
          description: data.description,
          defaultColor: data.default_color,
          originalDefaultColor: data.default_color,
          color: data.color,
          section: data.section,
          size: data.size,
          weight: data.weight,
          fabricType: data.fabric_type,
          pattern: data.pattern ? data.pattern : {
            type: ''
          },
          feeling: data.feeling,
          appearance: data.appearance,
          item: data.item,
          function: data.function,
          sustainable: data.sustainable,
          deliveryStatus: data.delivery_status,
          textile3DFile: {
            u3ma: data.fabric_files ? data.fabric_files.u3ma : undefined,
            zfab: data.fabric_files ? data.fabric_files.zfab : undefined
          }
        }
        this.$bvModal.show('itemDetailModal')
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async updateItem() {
      try {
        const itemEditForm: InstanceType<typeof ValidationObserver> = (this.$refs.itemDetail as any).$refs.editForm
        if (!itemEditForm) {
          return
        }
        const result = await itemEditForm.validate()
        if (!result) {
          return
        }
        this.modalLoading = true
        const payload: any = {
          name: this.selectedItem.name,
          description: this.selectedItem.description,
          composition: this.selectedItem.composition,
          size: this.selectedItem.size,
          weight: this.selectedItem.weight
        }
        if (!this.selectedItem.originalDefaultColor) {
          payload.default_color = this.selectedItem.defaultColor
        }
        const params = {
          color: this.selectedItem.color
        }
        const { data }: AxiosResponse = await this.$axios.post(
          `fabric/${this.selectedItem.itemCode}`,
          payload,
          {
            params: params
          }
        )

        if (!this.selectedItem.originalDefaultColor && this.selectedItem.defaultColor) {
          const targetDefaultColorIndex = this.tableProps.items.findIndex((value: IItemTable) => value.itemCode === this.selectedItem.itemCode && value.defaultColor)
          if (targetDefaultColorIndex !== -1) {
            const targetDefaultColor = this.tableProps.items[targetDefaultColorIndex]
            this.$set(this.tableProps.items, targetDefaultColorIndex, {
              ...targetDefaultColor,
              defaultColor: false
            })
          }
        }

        const targetIndex = this.tableProps.items.findIndex((value: IItemTable) => value.itemCode === this.selectedItem.itemCode && value.color === this.selectedItem.color)
        if (targetIndex !== -1) {
          this.$set(this.tableProps.items, targetIndex, {
            itemCode: data.item_code,
            defaultColor: data.default_color,
            color: data.color,
            name: data.name,
            section: data.section,
            thumbnail: data.thumbnail,
            deliveryStatus: data.delivery_status,
            textile3DFile: {
              u3ma: data.fabric_files ? data.fabric_files.u3ma : undefined,
              zfab: data.fabric_files ? data.fabric_files.zfab : undefined
            }
          })
        }

        const snackbarProps: SnackbarProps = {
          display: this.messages.success.itemEdit,
          show: true,
          color: 'success'
        }
        this.$bvModal.hide('itemDetailModal')
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
      }
    },
    async deleteItem(itemCode: string, color: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const params = {
          color: color
        }
        await this.$axios.delete(
          `fabric/${itemCode}`,
          {
            params
          }
        )
        this.submitFilter()
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.itemDelete.replace('#ITEM_CODE', itemCode).replace('#COLOR', color),
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    showItemDeleteConfirm(itemCode: string, color: string) {
      this.$bvModal.msgBoxConfirm(`品目情報（${itemCode}:${color}）を削除しますか？`, {
        title: '品目削除',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '削除',
        cancelTitle: 'キャンせル',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value: boolean) => {
        if (value) {
          this.deleteItem(itemCode, color)
        }
      })
    },
    show3dDeleteConfirm(itemCode: string, fileType: string, color: string) {
      this.$bvModal.msgBoxConfirm(`${itemCode}:${color}の3D画像（${fileType}）を削除しますか？`, {
        title: '品目削除',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '削除',
        cancelTitle: 'キャンせル',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value: boolean) => {
        if (value) {
          this.delete3D(itemCode, fileType, color)
        }
      })
    },
    async download3D(itemCode: string, fileType: string, color: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const params = {
          color: color
        }
        const {
          data: { token },
        }: AxiosResponse = await this.$axios.get(
          `fabric/${itemCode}/${fileType}/token`,
          {
            params: params
          }
        )
        window.open(
          `${apiConfig.baseUrl}/fabric/${itemCode}/${fileType}/download?token=${token}&color=${color}`,
          '_blank'
        )
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async delete3D(itemCode: string, fileType: string, color: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const params = {
          color: color
        }
        await this.$axios.delete(
          `fabric/${itemCode}/${fileType}`,
          {
            params: params
          }
        )
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.itemDelete.replace('#ITEM_CODE', itemCode).replace('#COLOR', color),
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
        await this.fetchList(true)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async previousPage() {
      if (this.tableProps.tokenStack.length > 0) {
        this.tableProps.nextToken = this.tableProps.tokenStack.pop()!!
        await this.fetchList()
      }
    },
    async nextPage() {
      this.tableProps.tokenStack.push(this.tableProps.currentToken)
      await this.fetchList()
    },
    async resetFilter() {
      this.tableProps.filterInfo = {
        itemCode: '',
        section: '',
        deliveryStatus: '',
        zfab: '',
        u3ma: ''
      }
      this.submitFilter()
    },
    async submitFilter() {
      this.clearSelect()
      this.clearPagination()
      await this.fetchList()
    },
    clearPagination() {
      this.tableProps.tokenStack = []
      this.tableProps.nextToken = null
      this.tableProps.currentToken = null
    },
    toggleSelect(selected: any) {
      const targetSkus = this.tableProps.items.map((item: IItemTable) => `${item.itemCode}:${item.color}`)
      this.selectedSku = this.selectedSku.filter((value: string) => !targetSkus.includes(value))
      if (selected && selected[0]) {
        this.selectedSku = [
          ...this.selectedSku,
          ...targetSkus
        ]
      }
    },
    onCheckAllChange() {
      const targetSkus = this.tableProps.items.map((item: IItemTable) => `${item.itemCode}:${item.color}`)
      const filteredSkus = this.selectedSku.filter((value: string) => targetSkus.includes(value))
      if (filteredSkus.length === this.tableProps.perPage) {
        this.checkedAll = [
          filteredSkus.length === this.tableProps.perPage
        ]
      } else {
        this.checkedAll = []
      }
    },
    clearSelect() {
      this.selectedSku = []
      this.checkedAll = []
    },
    async batchDelete() {
      try {
        this.modalLoading = true
        const payload: any = {
          skus: this.selectedSku
        }
        await this.$axios.delete(
          'fabrics',
          {
            data: payload
          }
        )
        await this.submitFilter()
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.batchDelete,
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
        this.$bvModal.hide('itemBatchDeleteModal')
      }
    }
  },
  async mounted() {
    await this.fetchList()
  },
  metaInfo: {
    title: '品目一覧'
  }
})
