







































































































































































































































































































import Vue from 'vue'
import {
  customerStatusRadioButtonList,
  skuRelationRadioButtonList,
  ICustomer,
  billingPlanDropdownList,
  BillingPlan
} from '@/assets/interfaces/Customer'
import { LabelValuePair } from '@/assets/interfaces/common'

interface Data {
  customerStatusRadioButtonList: LabelValuePair[]
  skuRelationRadioButtonList: LabelValuePair[]
  billingPlanDropdownList: LabelValuePair[]
}

export default Vue.extend({
  name: 'CustomerDetailModal',
  props: {
    customerInfo: {
      type: Object as () => ICustomer & { originalCustomerName: string, originalStatus: string, originalSKURelation: boolean },
      required: true
    }
  },
  data(): Data {
    return {
      customerStatusRadioButtonList: customerStatusRadioButtonList,
      skuRelationRadioButtonList: skuRelationRadioButtonList,
      billingPlanDropdownList: billingPlanDropdownList
    }
  },
  computed: {
    billingPlanText(): string {
      return this.customerInfo.billingPlan ? (BillingPlan as any)[this.customerInfo.billingPlan] : '課金プランを選択'
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: any) {
      return dirty || validated ? valid : null
    }
  }
})
