export enum BillingType {
  UNIT_ITEM = '単品購入',
  IN_SUB = 'サブスク',
  OUT_SUB = '単品購入(サブスク超過分)',
  ADD_COLOR = '追加購入',
  RE_DOWNLOAD = '再ダウンロード'
}

export interface ITotalDownload3DTable {
  sapCustomerCode: string
  customerName: string
  divisionName: string
  userName: string
  email: string
  itemCode: string
  color: string
  name: string
  fileType: 'u3ma' | 'zfab' | 'texture'
  thumbnail: string
  downloadAt: string
  isBillingTarget: boolean
}

export interface ITotalDownload3DExport {
  sapCustomerCode: string
  customerName: string
  divisionName: string
  userName: string
  email: string
  itemCode: string
  color: string
  name: string
  fileType: 'u3ma' | 'zfab' | 'texture'
  downloadAt: string
  amount: number
  isBillingTarget: boolean
  billingType: string
  billingPlan: string
}
