import Vue from 'vue'
import { BillingPlan } from '@/assets/interfaces/Customer'

Vue.filter('empty', (value: any) => {
  if (!value) {
    return '-'
  }
  return value
})

Vue.filter('billingPlan', (value: any) => {
  if (!value) {
    return ''
  }
  const result = (BillingPlan as any)[value]
  if (!result) {
    return value
  }
  return result
})

export default {}
