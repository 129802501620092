










































































































































































import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { ValidationObserver } from 'vee-validate'

import { clearEmpties } from '@/assets/utils'
import { TableField } from '@/assets/interfaces/common'
import {
  ICustomer,
  ICustomerEntry,
  ICustomerTable
} from '@/assets/interfaces/Customer'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import CustomerDetailModal from '@/components/Customer/CustomerDetailModel.vue'
import CustomerEntryModal from '@/components/Customer/CustomerEntryModel.vue'

interface Data {
  newEntryCustomer: ICustomerEntry
  selectedCustomer: ICustomer & { originalCustomerName: string, originalStatus: string, originalSKURelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE', originalBillingPlan: string, originalAssetAmount: string }
  tableProps: {
    headers: Array<TableField>
    items: Array<ICustomerTable>
    page: number
    perPage: number
    filter: string | null
    filterOn: string[]
    noDataText: string
    tableLoading: boolean
    totalItems: number
  }
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
    }
    success: {
      customerCreate: string
      customerEdit: string
      customerDelete: string
    }
  }
  modalLoading: boolean
}

export default Vue.extend({
  name: 'CustomerList',
  components: {
    CustomerDetailModal,
    CustomerEntryModal
  },
  data(): Data {
    return {
      newEntryCustomer: {
        customerName: '',
        sapCustomerCode: '',
        skuRelation: 'ITEM_CODE_AND_COLOR',
        fabricDownloadQuota: '30',
        textureDownloadQuota: '30',
        totalDownloadQuota: '30',
        billingPlan: 'PAY_AS_YOU_GO',
        assetAmount: '0'
      },
      selectedCustomer: {
        customerId: '',
        customerName: '',
        originalCustomerName: '',
        originalStatus: '',
        originalSKURelation: 'ITEM_CODE_AND_COLOR',
        originalBillingPlan: 'PAY_AS_YOU_GO',
        originalAssetAmount: '0',
        skuRelation: 'ITEM_CODE_AND_COLOR',
        billingPlan: 'PAY_AS_YOU_GO',
        assetAmount: '0',
        fabricDownloadQuota: '30',
        textureDownloadQuota: '30',
        totalDownloadQuota: '30',
        sapCustomerCode: '',
        status: '',
        createdAt: ''
      },
      tableProps: {
        headers: [
          {
            key: 'customerId',
            label: '顧客ID',
            sortable: false,
          },
          {
            key: 'customerName',
            label: '顧客名',
            sortable: true
          },
          {
            key: 'billingPlan',
            label: 'プラン',
            sortable: false
          },
          {
            key: 'assetAmount',
            label: 'サブスクセット数',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'totalDownloadQuota',
            label: '月間ダウンロード上限数（合計）',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'skuRelation',
            label: 'SKU連携',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'status',
            label: 'ステータス',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'actions',
            label: 'アクション',
            class: 'text-center',
            sortable: false
          }
        ],
        items: [],
        perPage: 10,
        page: 1,
        totalItems: 0,
        noDataText: 'データが見つかりません',
        filter: null,
        filterOn: ['customerName'],
        tableLoading: false
      },
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。'
        },
        success: {
          customerCreate: '顧客情報は作成されました。',
          customerEdit: '顧客情報は更新されました。',
          customerDelete: '顧客情報(#CUSTOMER_ID)は削除されました。'
        }
      },
      modalLoading: false
    }
  },
  computed: {
    totalPage(): number {
      return Math.ceil(this.tableProps.totalItems / this.tableProps.perPage)
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger'
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    async fetchList() {
      try {
        this.tableProps.tableLoading = true
        const params = {
          all_result: 'True'
        }
        const { data }: AxiosResponse = await this.$axios.get(
          'customers',
          {
            params: params
          }
        )
        this.tableProps.items = data.customers.map((item: any): ICustomerTable => {
          return {
            customerId: item.customer_id,
            customerName: item.customer_name,
            skuRelation: item.sku_relation,
            status: item.status,
            billingPlan: item.billing_plan,
            assetAmount: item.asset_amount,
            totalDownloadQuota: item.total_download_quota
          }
        })
        this.tableProps.totalItems = this.tableProps.items.length
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.tableProps.tableLoading = false
      }
    },
    showEntryModal() {
      this.newEntryCustomer = {
        customerName: '',
        sapCustomerCode: '',
        skuRelation: 'ITEM_CODE_AND_COLOR',
        fabricDownloadQuota: '30',
        textureDownloadQuota: '30',
        totalDownloadQuota: '30',
        billingPlan: 'PAY_AS_YOU_GO',
        assetAmount: '0'
      }
      this.$bvModal.show('customerEntryModal')
    },
    async showDetailModal(customerId: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const { data }: AxiosResponse = await this.$axios.get(
          `customer/${customerId}`
        )
        this.selectedCustomer = {
          customerId: data.customer_id,
          customerName: data.customer_name,
          originalCustomerName: data.customer_name,
          originalStatus: data.status,
          originalSKURelation: data.sku_relation,
          originalBillingPlan: data.billing_plan,
          originalAssetAmount: data.asset_amount,
          skuRelation: data.sku_relation,
          sapCustomerCode: data.sap_customer_code,
          billingPlan: data.billing_plan,
          assetAmount: data.asset_amount,
          fabricDownloadQuota: data.fabric_download_quota,
          textureDownloadQuota: data.texture_download_quota,
          totalDownloadQuota: data.total_download_quota,
          status: data.status,
          createdAt: data.created_at
        }
        this.$bvModal.show('customerDetailModal')
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async createCustomer() {
      try {
        const customerEntryForm: InstanceType<typeof ValidationObserver> = (this.$refs.customerEntry as any).$refs.entryForm
        if (!customerEntryForm) {
          return
        }
        const result = await customerEntryForm.validate()
        if (!result) {
          return
        }
        this.modalLoading = true
        const payload = {
          customer_name: this.newEntryCustomer.customerName,
          sap_customer_code: this.newEntryCustomer.sapCustomerCode ? this.newEntryCustomer.sapCustomerCode : '',
          sku_relation: this.newEntryCustomer.skuRelation,
          fabric_download_quota: Number(`${this.newEntryCustomer.fabricDownloadQuota}`),
          texture_download_quota: Number(`${this.newEntryCustomer.textureDownloadQuota}`),
          total_download_quota: Number(`${this.newEntryCustomer.totalDownloadQuota}`),
          billing_plan: this.newEntryCustomer.billingPlan,
          asset_amount: Number(`${this.newEntryCustomer.assetAmount}`)
        }
        clearEmpties(payload, true)
        await this.$axios.post(
          'customer',
          payload
        )
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.customerCreate,
          show: true,
          color: 'success'
        }
        this.$bvModal.hide('customerEntryModal')
        this.$eventbus.$emit('showSnackbar', snackbarProps)
        this.clearPagination()
        await this.fetchList()
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
      }
    },
    async updateCustomer() {
      try {
        const customerEditForm: InstanceType<typeof ValidationObserver> = (this.$refs.customerDetail as any).$refs.editForm
        if (!customerEditForm) {
          return
        }
        const result = await customerEditForm.validate()
        if (!result) {
          return
        }
        this.modalLoading = true
        const payload = {
          customer_name: this.selectedCustomer.customerName !== this.selectedCustomer.originalCustomerName ? this.selectedCustomer.customerName : '',
          sap_customer_code: this.selectedCustomer.sapCustomerCode ? this.selectedCustomer.sapCustomerCode : '',
          status: this.selectedCustomer.status !== this.selectedCustomer.originalStatus ? this.selectedCustomer.status : '',
          sku_relation: this.selectedCustomer.skuRelation !== this.selectedCustomer.originalSKURelation ? this.selectedCustomer.skuRelation : '',
          fabric_download_quota: Number(`${this.selectedCustomer.fabricDownloadQuota}`),
          texture_download_quota: Number(`${this.selectedCustomer.textureDownloadQuota}`),
          total_download_quota: Number(`${this.selectedCustomer.totalDownloadQuota}`),
          billing_plan: this.selectedCustomer.billingPlan !== this.selectedCustomer.originalBillingPlan ? this.selectedCustomer.billingPlan : '',
          asset_amount: this.selectedCustomer.assetAmount !== this.selectedCustomer.originalAssetAmount ? Number(`${this.selectedCustomer.assetAmount}`) : ''
        }
        clearEmpties(payload, true)
        const { data }: AxiosResponse = await this.$axios.post(
          `customer/${this.selectedCustomer.customerId}`,
          payload
        )
        const targetIndex = this.tableProps.items.findIndex((value: ICustomerTable) => value.customerId === this.selectedCustomer.customerId)
        if (targetIndex !== -1) {
          this.$set(this.tableProps.items, targetIndex, {
            customerId: data.customer_id,
            customerName: data.customer_name,
            status: data.status,
            skuRelation: data.sku_relation,
            billingPlan: data.billing_plan,
            assetAmount: data.asset_amount,
            totalDownloadQuota: data.total_download_quota
          } as ICustomerTable)
        }
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.customerEdit,
          show: true,
          color: 'success'
        }
        this.$bvModal.hide('customerDetailModal')
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.modalLoading = false
      }
    },
    async deleteCustomer(customerId: string) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        await this.$axios.delete(
          `customer/${customerId}`
        )
        this.clearPagination()
        this.fetchList()
        const snackbarProps: SnackbarProps = {
          display: this.messages.success.customerDelete.replace('#CUSTOMER_ID', customerId),
          show: true,
          color: 'success'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    showCustomerDeleteConfirm(customerId: string) {
      this.$bvModal.msgBoxConfirm(`顧客情報（${customerId}）を削除しますか？`, {
        title: '顧客削除',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '削除',
        cancelTitle: 'キャンせル',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value: boolean) => {
        if (value) {
          this.deleteCustomer(customerId)
        }
      })
    },
    previousPage() {
      if (this.tableProps.page > 1) {
        this.tableProps.page -= 1
      }
    },
    nextPage() {
      if (this.tableProps.page < this.totalPage) {
        this.tableProps.page += 1
      }
    },
    onEntryFormChange(customer: ICustomerEntry) {
      this.newEntryCustomer = customer
      this.setupQuota(this.newEntryCustomer)
    },
    onUpdateFormChange(customer: ICustomer & { originalCustomerName: string, originalStatus: string, originalSKURelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE', originalBillingPlan: string, originalAssetAmount: string }) {
      this.selectedCustomer = customer
      this.setupQuota(this.selectedCustomer)
    },
    setupQuota(customer: ICustomerEntry | ICustomer & { originalCustomerName: string, originalStatus: string, originalSKURelation: 'ITEM_CODE_AND_COLOR' | 'ITEM_CODE', originalBillingPlan: string, originalAssetAmount: string }) {
      if (customer.billingPlan) {
        switch (customer.billingPlan) {
          case 'PAY_AS_YOU_GO':
            customer.fabricDownloadQuota = '30'
            customer.textureDownloadQuota = '30'
            customer.totalDownloadQuota = '30'
            customer.assetAmount = '0'
            break
          case 'TX':
            customer.fabricDownloadQuota = '50'
            customer.textureDownloadQuota = '50'
            customer.totalDownloadQuota = '50'
            customer.assetAmount = '5'
            break
          case 'PREMIUM':
            customer.fabricDownloadQuota = '50'
            customer.textureDownloadQuota = '50'
            customer.totalDownloadQuota = '50'
            customer.assetAmount = '5'
            break
          case 'M2M':
            customer.fabricDownloadQuota = '-1'
            customer.textureDownloadQuota = '-1'
            customer.totalDownloadQuota = '-1'
            customer.assetAmount = '0'
            break
          default:
            customer.fabricDownloadQuota = '-1'
            customer.textureDownloadQuota = '-1'
            customer.totalDownloadQuota = '-1'
            customer.assetAmount = '0'
            break
        }
      }
    },
    clearPagination() {
      this.tableProps.page = 1
    },
    onFiltered(filteredItems: Array<ICustomerTable>) {
      this.tableProps.page = 1
      this.tableProps.totalItems = filteredItems.length
    }
  },
  async mounted() {
    await this.fetchList()
  },
  metaInfo: {
    title: '顧客一覧'
  }
})
