import Vue from 'vue'
import VueRouter, {
  RawLocation,
  Route,
  RouteConfig
} from 'vue-router'
import Home from '@/views/Home.vue'
import LoginRedirect from '@/views/LoginRedirect.vue'
import LogoutRedirect from '@/views/LogoutRedirect.vue'
import ItemDataUpload from '@/views/Item/ItemDataUpload.vue'
import Item3DUpload from '@/views/Item/Item3DUpload.vue'
import ItemList from '@/views/Item/List.vue'
import CustomerList from '@/views/Customer/List.vue'
import UserList from '@/views/User/List.vue'
import Error from '@/views/Error.vue'
import Default from '@/layouts/Default.vue'
import DownloadHistoryList from '@/views/DownloadHistory/List.vue'

Vue.use(VueRouter)

const { isNavigationFailure, NavigationFailureType } = VueRouter
const originalPush = VueRouter.prototype.push

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: Default,
      protect: false
    }
  },
  {
    path: '/callback/login',
    name: 'CallbackLogin',
    component: LoginRedirect,
    meta: {
      protect: false
    }
  },
  {
    path: '/callback/logout',
    name: 'CallbackLogout',
    component: LogoutRedirect,
    meta: {
      protect: false
    }
  },
  {
    path: '/Item/ItemDataUpload',
    name: 'Item-ItemDataUpload',
    component: ItemDataUpload,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Item/Item3DUpload',
    name: 'Item-Item3DUpload',
    component: Item3DUpload,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Item/List',
    name: 'Item-List',
    component: ItemList,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Customer/List',
    name: 'Customer-List',
    component: CustomerList,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Customer/:id/User',
    name: 'Customer-User-List',
    component: UserList,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/DownloadHistory/List',
    name: 'Item-DownloadHistory-List',
    component: DownloadHistoryList,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: Error,
    meta: {
      layout: Default,
      protect: true
    },
    props: {
      detail: {
        msg: 'Page Not Found',
        returnPage: {
          msg: 'ホームに戻る',
          name: 'Home',
          url: '/'
        }
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function doPush(location: RawLocation): Promise<any> {
  try {
    const result = await (originalPush as (location: RawLocation) => Promise<Route>).call(router, location)
    return result
  } catch (e: any) {
    if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
      throw e
    }
  }
}

function push(location: RawLocation, onComplete?: any, onAbort?: any): void
function push(location: RawLocation): Promise<any>
function push(location: RawLocation, onComplete?: any, onAbort?: any): Promise<any> | void{
  if (onComplete || onAbort) {
    (originalPush as (location: RawLocation, onComplete?: any, onAbort?: any) => void).call(router, location, onComplete, onAbort)
  } else {
    return doPush(location)
  }
}

VueRouter.prototype.push = push

export default router;
